// Generated by Framer (26d4882)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VkjdtQe_B"];

const serializationHash = "framer-sjRx3"

const variantClassNames = {VkjdtQe_B: "framer-v-1ehf679"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, TJ50DBz4k: link ?? props.TJ50DBz4k, yW12noOqm: icon ?? props.yW12noOqm ?? {src: "https://framerusercontent.com/images/srDX9MlLUopyBZorbTe1krqS5KE.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, yW12noOqm, TJ50DBz4k, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VkjdtQe_B", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sjRx3", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={TJ50DBz4k} openInNewTab><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 31, intrinsicWidth: 31, pixelHeight: 31, pixelWidth: 31, sizes: "min(31px, 100vw)", ...toResponsiveImage(yW12noOqm)}} className={`${cx("framer-1ehf679", className)} framer-8q4yaf`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"VkjdtQe_B"} ref={ref ?? ref1} style={{...style}}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sjRx3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sjRx3 .framer-8q4yaf { display: block; }", ".framer-sjRx3 .framer-1ehf679 { height: 31px; overflow: visible; position: relative; text-decoration: none; width: 31px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"yW12noOqm":"icon","TJ50DBz4k":"link"}
 * @framerImmutableVariables true
 */
const FramerkwOdkDV2b: React.ComponentType<Props> = withCSS(Component, css, "framer-sjRx3") as typeof Component;
export default FramerkwOdkDV2b;

FramerkwOdkDV2b.displayName = "Social Link";

FramerkwOdkDV2b.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerkwOdkDV2b, {yW12noOqm: {__defaultAssetReference: "data:framer/asset-reference,srDX9MlLUopyBZorbTe1krqS5KE.svg?originalFilename=Youtube.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}, TJ50DBz4k: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerkwOdkDV2b, [])